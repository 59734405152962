<script setup lang="ts">
  import menu from "~/data/header-menu.json";
  import jamesHardieMenu from "~/data/james-hardie-menu.json";
  import {useAcfOption} from "~/composables/ApiHelper";
  import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
  import { useGQlData } from "~/composables/GraphQlHelper";
  import { getDesignStyles } from "~/composables/GraphQlQueries";
  import {faChevronDown} from "@fortawesome/free-solid-svg-icons";

  const props = defineProps<{
    menuName?: string;
  }>();

  let menuObj = menu;
  let menuItemTextClass = 'text-white';
  let navbarClass = '';
  let jhStyles: any = [];

  let menuText = 'Menu';
  if (props.menuName !== undefined) {
    if (props.menuName === 'james-hardie') {
      menuObj = jamesHardieMenu;
      menuItemTextClass = 'text-var-primary jh-navbar-item';
      navbarClass = 'jh-dropdown';

      const jhStylesData = await useGQlData(getDesignStyles, {}, 'jh-styles-query');

      jhStylesData?.jhStyles?.nodes.forEach((element: any) => {
        jhStyles.push(element);
      });
    }
  }

  const contactDetails = await useAcfOption('contact_details');
</script>

<template>
  <button type="button"
          data-bs-target="#sidebar"
          data-bs-toggle="offcanvas"
          aria-controls="sidebar"
          aria-label="Toggle menu"
          class="navbar-toggler border-0 shadow-none px-0 me-2">
    <span v-if="menuName !== 'james-hardie'" :class="`text-uppercase ${menuItemTextClass} text-menu ff-montserrat`">
      Menu
    </span>
    <span v-else>
      <svg class="jh-menu-icon" xmlns="http://www.w3.org/2000/svg" width="25" height="40" viewBox="0 0 34.33 28.75">
        <g id="Group_30" data-name="Group 30" transform="translate(-568.699 -29.38)">
          <line id="Line_10" data-name="Line 10" x1="34.33" transform="translate(568.699 43.755)" fill="none" stroke="#091a2c" stroke-width="0.75"/>
          <line id="Line_11" data-name="Line 11" x1="34.33" transform="translate(568.699 29.755)" fill="none" stroke="#091a2c" stroke-width="0.75"/>
          <line id="Line_12" data-name="Line 12" x1="34.33" transform="translate(568.699 57.755)" fill="none" stroke="#091a2c" stroke-width="0.75"/>
        </g>
      </svg>
    </span>
  </button>
  <div id="sidebar" aria-labelledby="sidebar-label" class="offcanvas offcanvas-end text-bg-dark">
    <div class="offcanvas-header justify-content-end bg-dark">
      <button type="button"
              aria-label="Close"
              data-bs-dismiss="offcanvas"
              class="ms-auto btn btn-link text-decoration-none border-0 shadow-none px-0">
        <span class="text-white text-uppercase text-menu ff-montserrat">Close</span>
      </button>
    </div>
    <div class="offcanvas-body navbar-nav justify-content-xl-end flex-grow-1 bg-transparent">
      <ul id="sidebar-nav-ul" class="navbar-nav">
        <template v-for="item in menuObj?.menuItems?.nodes">
          <li v-if="item?.childItems?.nodes?.length > 0" :class="`${navbarClass} nav-item dropdown navbar-dropdown`">
            <!-- nav link for desktop -->
            <a :class="`${menuItemTextClass} nav-link text-uppercase no-caret dropdown-toggle d-none d-xl-flex align-items-center justify-content-between justify-content-xl-start text-nowrap`"
               :target="item.target || ''"
               :href="item.uri" aria-expanded="false">
              {{item.label}}
              <div class="d-inline-block fa-fw ms-1">
                <FontAwesomeIcon :icon="faChevronDown" fixed-width/>
              </div>
            </a>
            <!-- nav link for mobile -->
            <a class="nav-link d-inline-block d-xl-none text-uppercase text-white no-caret w-75" :target="item.target || ''" :href="item.uri">{{item.label}}</a>
            <a class="nav-link d-inline-block d-xl-none no-caret dropdown-toggle w-25 text-end" aria-expanded="false" data-bs-toggle="dropdown" role="button" href="#">
              <div class="d-inline-block fa-fw">
                <FontAwesomeIcon :icon="faChevronDown" class="text-dark" fixed-width/>
              </div>
            </a>
            <ul v-if="menuName !== 'james-hardie' && item.label !== 'Browse Styles'" class="dropdown-menu shadow rounded-0 p-0 pt-xl-3 pb-xl-2 border-0">
              <template v-for="sub in item?.childItems?.nodes">
                <li><a class="dropdown-item mb-2 text-uppercase" :target="sub.target || ''" :href="sub.uri">{{sub.label}}</a></li>
              </template>
            </ul>
            <ul v-else class="dropdown-menu rounded-0 p-0 pt-xl-3 border-0 jh-styles-menu">
              <div class="menu-wrapper">
                <template v-for="sub in jhStyles">
                  <li class="child-style d-none d-xl-flex" :style="`background: ${sub?.jhStylesDetails?.colorScheme}; height: 80px`">
                    <a :href="`${sub.uri}`" class="d-flex w-100" :target="sub.target || ''">
                      <div class="col-md-5 h-100">
                        <img class="child-style-img" :src="sub?.featuredImage?.node?.mediaItemUrl"/>
                      </div>
                      <div class="col-md-7 h-100 d-flex align-items-end">
                        <a class="ff-ivyjournal dropdown-item mb-2 mb-md-0 pb-md-0 text-uppercase" :target="sub.target || ''" :href="`${sub.uri}`">{{sub.title}}</a>
                      </div>
                    </a>
                  </li>
                  <li class="child-style d-xl-none d-flex">
                      <a :target="sub.target || ''" class="dropdown-item mb-2 mb-md-0 pb-md-0 text-uppercase" :href="`${sub.uri}`">{{sub.title}}</a>
                  </li>
                </template>
              </div>
            </ul>
          </li>
          <li v-else-if="menuName === 'james-hardie' && item.label === 'Contact Us'" class="nav-item">
            <a :class="`nav-link ${menuItemTextClass} text-uppercase mx-xl-1 d-flex align-items-center justify-content-between justify-content-xl-start text-nowrap`"
               aria-current="page"
               target="_blank"
               :href="item.uri">
              {{item.label}}
            </a>
          </li>
          <li v-else class="nav-item">
            <a :class="`nav-link ${menuItemTextClass} text-uppercase mx-xl-1 d-flex align-items-center justify-content-between justify-content-xl-start text-nowrap`"
               aria-current="page"
               :href="item.uri"
               :target="item.target || ''">
              {{item.label}}
            </a>
          </li>
        </template>
        <li class="nav-item d-none d-xl-block">
          <span :class="`nav-link ${menuItemTextClass} text-uppercase mx-xl-2 separator`"></span>
        </li>
        <li class="nav-item d-none d-xl-block">
          <a :class="`nav-link ${menuItemTextClass} text-uppercase mx-xl-2 fw-bold d-flex align-items-center justify-content-between justify-content-xl-start text-nowrap`"
             aria-current="page"
             :href="`tel:${contactDetails.contact_number}`">
            {{contactDetails.contact_number}}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
<style>
  @media (min-width: 1200px) {
      .navbar-nav .navbar-dropdown:hover .dropdown-menu.jh-styles-menu .menu-wrapper {
          display: flex !important;
      }

      .navbar-nav .navbar-dropdown.jh-dropdown:hover {
        background: rgb(211, 211, 211);

        .dropdown-menu.jh-styles-menu {
          background: rgb(211, 211, 211);
          min-width: 146px !important;
        }
      }

      .jh-styles-menu .menu-wrapper {
        width: 100%;
        position: fixed !important;
        left: 0 !important;
        justify-content: space-around;
        background: rgb(211, 211, 211);
        padding: 1.5rem !important;
        gap: 20px;

        .child-style {
          padding: 0.5rem;
          background: #F3EAD6;
          flex-basis: 100%;
          display: flex;
        }

        .child-style-img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      
      .jh-styles-menu .menu-wrapper .child-style .dropdown-item {
        font-size: 20px !important;
        text-transform: capitalize !important;
        text-wrap: wrap;
        line-height: 1;
        
        &:hover {
          background: transparent !important;
        }
      }

      .jh-styles-menu {
        background-color: transparent;
      }
  }

  .navbar-nav .nav-link.jh-navbar-item:hover, .navbar-nav .nav-link.jh-navbar-item:focus {
    color: var(--var-primary-color) !important;
  }

  .navbar-dark.bg-dark .jh-menu-icon {
    > g > line {
      stroke: var(--var-secondary-color);
    }
  }

  .jh-menu-icon {
    color: var(--var-menu-bg-color);
  }

</style>
