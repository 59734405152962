<script setup lang="ts">
  import Logo from "~/components/Header/Logo.vue";
  import Menu from "~/components/Header/Menu.vue";

  const props = defineProps<{ 
    sticky: boolean;
    menu?: string;
  }>();
  const className = props.sticky ? 'sticky-top bg-dark' : 'fixed-top';
  const id = props.sticky ? 'navbar-sticky' : 'navbar';
</script>

<template>
  <nav :id="id" :class="`navbar navbar-expand-xl border-0 navbar-dark ${className} py-3`">
    <div class="container-fluid flex-nowrap">
      <Logo :menuName="menu"/>
      <Menu :menuName="menu"/>
    </div>
  </nav>
</template>

<style lang="css" scoped>
  .navbar {
    height: 73px !important;
    transition: background-color 0.3s ease-in-out;
  }
</style>
